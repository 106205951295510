html {
    height: 100%;
}

body {
    height: 100%;
    background: url(img/pattern.png) repeat;
    font: normal 1em fira_sanslight;
    color: #4a545d;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wrapper {
    position: relative;
    min-height: 100%;
    min-width: 970px;
    height: auto !important;
    overflow: hidden;
}

.wrapper:after {
    content: "";
    height: 80px;
    display: block;
}

.page-footer {
    height: 80px;
    margin-top: -80px;
}

.container-big {
    max-width: 1200px;
    min-width: 960px;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.page-header {
    height: 119px;
    background: #eff3f7 url(img/line.png) bottom center no-repeat;
    position: relative;
    margin-bottom: 39px;
}

.page-header .header-logo-link {
    position: absolute;
    top: 39px;
    left: 23px;
    color: inherit;
    text-decoration: none;
}

.page-header .header-logo-text {
    position: absolute;
    left: -9999px;
}

.page-header .socials {
    position: absolute;
    right: -112px;
    top: 36px;
}

.socials {
    margin: 0;
    padding: 0;
    min-width: 260px;
}

.socials .socials-item {
    list-style: none;
    display: inline-block;
    height: 47px;
    width: 47px;
    margin-left: 19px;
}

.socials .socials-item:first-child {
    margin-left: 0;
}

.socials .socials-link {
    height: 100%;
    width: 100%;
    text-indent: -9999px;
}

.socials .socials-link.gt {
    background-position: -209px -3px;
}

.socials .socials-link:hover {
    background-position-y: -65px;
}

.sidebar {
    width: 250px;
    float: left;
    margin-left: 25px;
}

.navigation {
    overflow: hidden;
    background: #eff3f7;
    border-radius: 4px;
    margin-bottom: 26px;
}

.navigation .navigation-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation .navigation-item {
    border-top: 2px solid #3ba8c0;
    margin: 0 11px;
}

.navigation .navigation-item:first-child {
    border-top: none;
}

.navigation .navigation-item.current {
    background: #517ec0;
    border-bottom: none;
    padding-left: 11px;
    margin-bottom: -2px;
}

.navigation .navigation-item.current .navigation-link {
    color: #f9fafb;
    font-size: 22px;
}

.navigation .navigation-link {
    font-family: fira_sansbook, sans-serif;
    display: block;
    font-size: 20px;
    text-decoration: none;
    color: #959aa8;
    margin-left: 16px;
    padding: 20px 0;
}

.navigation .navigation-link:hover,
.navigation-link.current {
    color: #3ba8c0;
}

.navigation-link.current {
    font-style: italic;
}

.contacts {
    font-style: normal;
    font-family: fira_sansbook, sans-serif;
    background: #3ba8c0;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 26px;
    color: #fff;
}

.contacts .contacts-header {
    background: #3ba8c0;
    border-radius: 4px 4px 0 0;
    padding: 8px 19px 1px;
}

.contacts .header-inner-text {
    font-size: 20px;
    margin: 0;
    display: block;
}

.contacts .contacts-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.contacts .contacts-link {
    display: block;
    text-decoration: none;
    padding: 6px 0 6px 82px;
}

.contacts .contact-text {
    line-height: 1.3;
    font-size: 13px;
    color: #fff;
}

.contacts .contacts-item {
    background: url(img/cont.png) 42px center no-repeat;
}

.contacts .contacts-item-mail {
    background-color: #41b9d4;
    background-position-y: 11px;
}

.contacts .contacts-item-github {
    background-color: #3094ab;
    background: url(img/GitHub-Mark-32px.png) 42px center no-repeat;
}

.contacts .contacts-item-phone {
    background-color: #48cbe8;
}

.contacts .contact-text:hover {
    border-bottom: 1px solid #fff;
}

.variable-content {
    width: 650px;
    float: right;
}

.container {
    width: 960px;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.clearfix:after,
.clearfix:before,
.container:after,
.container:before {
    content: " ";
    display: table;
}

.clearfix:after,
.container:after {
    clear: both;
}

.aboutbox {
    background: #eff3f7;
    border-radius: 4px;
    margin-left: 23px;
    margin-bottom: 26px;
    width: 652px;
}

.aboutbox .aboutbox-header {
    font-family: fira_sansbook, sans-serif;
    font-size: 27px;
    font-weight: 400;
    color: #3ba8c0;
    border-bottom: 2px #3ba8c0 solid;
    margin: 0 0 23px 0;
    padding: 13px 23px;
    display: inline-block;
}

.aboutbox-body {
    padding-bottom: 50px;
}

.image-wrap {
    float: left;
    margin-left: 55px;
    margin-top: 18px;
}

.avatar-box {
    width: 142px;
    height: 142px;
    border-radius: 50% 50%;
    overflow: hidden;
    border: 2px solid #3ba8c0;
}

.avatar {
    width: 100%;
}

.aboutbox .worksheet-wrap {
    float: right;
    width: 400px;
}

.worksheet-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.worksheet-info {
    float: left;
    margin-left: 8px;
    margin-top: 2px;
}

.worksheet-item {
    margin-bottom: 8px;
}

.worksheet-what {
    font-family: fira_sansmedium, sans-serif;
    font-size: 16px;
    float: left;
}

.worksheet-info {
    font-family: fira_sansbook, sans-serif;
    font-size: 14px;
    overflow: hidden;
    max-width: 218px;
}

.worksheet-info .skills-list {
    width: 224px;
    padding: 0;
    list-style: none;
}

.skills-list .skill-item {
    display: inline-block;
    font-family: ProximaNovaCondensedSemibold, sans-serif;
    background: #3ba8c0;
    background: -moz-linear-gradient(top, #3ba8c0 0, #3ba8c0 49%, #2e8ca2 50%, #2e8ca2 99%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #3ba8c0), color-stop(49%, #3ba8c0), color-stop(50%, #2e8ca2), color-stop(99%, #2e8ca2));
    background: -webkit-linear-gradient(top, #3ba8c0 0, #3ba8c0 49%, #2e8ca2 50%, #2e8ca2 99%);
    background: -o-linear-gradient(top, #3ba8c0 0, #3ba8c0 49%, #2e8ca2 50%, #2e8ca2 99%);
    background: -ms-linear-gradient(top, #3ba8c0 0, #3ba8c0 49%, #2e8ca2 50%, #2e8ca2 99%);
    background: linear-gradient(to bottom, #3ba8c0 0, #3ba8c0 49%, #2e8ca2 50%, #2e8ca2 99%);
    border-radius: 4px;
    padding: 0 7px;
    margin-right: 6px;
    color: #fff;
    line-height: 1.2;
    min-width: 40px;
    text-align: center;
}

.aboutbox .aboutbox-list {
    list-style: none;
    padding: 0 0 0 50px;
    margin: 0;
}

.aboutbox .aboutbox-item {
    padding: 0 0 0 76px;
    margin-bottom: 20px;
}

.aboutbox .aboutbox-item:last-child {
    margin-bottom: 0;
}

.aboutbox-item .where {
    font-family: fira_sansbook, sans-serif;
    font-size: 16px;
}

.aboutbox-item .period {
    font-family: fira_sanslight, sans-serif;
    font-size: 15px;
    line-height: 1;
}

.aboutbox-item.icon-work {
    background: url(img/bag.png) 9px center no-repeat;
}

.aboutbox-item.icon-college {
    background: url(img/college.png) 9px center no-repeat;
}

.aboutbox-item.icon-course {
    background: url(img/course.png) 17px center no-repeat;
}

.icon-link, .icon-link:visited {
    margin-left: 30px;
    color: #4a545d;
    text-decoration: none;
}

.icon-link:hover, .icon-link:focus {
    text-decoration: underline;
}

.header-big-wrap {
    background: #48cbe8;
    border-bottom: 17px solid #67dffa;
    padding: 42px 20px 38px;
    border-radius: 5px 5px 0 0;
    text-align: center;
    margin-bottom: 41px;
}

.aboutbox-header-big {
    padding: 0;
    margin: 0;
    text-align: center;
    height: 25px;
    font-size: 27px;
    font-family: fira_sansbook, sans-serif;
    color: #fff;
    font-weight: 400;
    background-color: #48cbe8;
}

.contact-form-wrap {
    width: 405px;
    margin: 0 auto;
    padding-top: 20px;
}

.contact-form .textarea {
    height: 182px;
}

.contact-form .form-line .form-group {
    width: 48%;
}

.contact-form .captcha {
    width: 48%;
}

.contact-form .captcha-wrap {
    margin: 0 auto 31px;
}

.contact-form .input-captcha {
    height: 32px;
    width: 48%;
    margin-top: 21px;
    padding: 6px 12px;
}

.contact-form .button-group button[type=reset] {
    float: right;
}

.contact-form .captcha-wrap .label {
    font-size: 16px;
}

button,
input,
textarea {
    outline: 0;
}

.input,
.textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 12px;
    width: 100%;
    padding: 12px 12px;
}

.textarea {
    resize: none;
}

.form-group {
    margin-bottom: 12px;
}

.btn {
    border-radius: 5px;
    background-color: #48cbe8;
    height: 33px;
    border: solid #3ba8c0;
    border-width: 0 0 2px 0;
    color: #f6f6f6;
    font-family: fira_sansbook, sans-serif;
    font-size: 20px;
    padding: 0 20px;
}

.btn:active {
    border-top: solid 2px #3ba8c0;
}

.btn:hover {
    border-bottom: none;
}

.btn-res {
    background: #849db5;
    border-color: #687b8f;
}

.btn-res:active {
    border-top: solid 2px #687b8f;
}

.input {
    display: block;
    height: 41px;
    color: #48cbe8;
    background-color: #fff;
    border: 1px solid #48cbe8;
    border-radius: 4px;
    outline: 0;
}


.label {
    font-family: fira_sansbook, sans-serif;
    color: #58697a;
    font-size: 18px;
}

.textarea {
    display: block;
    width: 100%;
    height: 110px;
    color: #48cbe8;
    border: 1px solid #48cbe8;
    border-radius: 4px;
}

.header-icon-link {
    width: 120%;
}

.projects-wrapper {
    margin: 0 20px;
}

.projects-wrapper .projects-list {
    padding: 0;
    margin: 0 0 0 -25px;
}

.projects-list .projects-item {
    width: 183px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative;
    margin-bottom: 38px;
    margin-left: 25px;
}

.projects-item .hover-img {
    width: 100%;
    height: 129px;
    border-radius: 4px;
    margin-bottom: 3px;
}

.projects-item .project-img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 2px solid #3ba8c0;
    border-radius: 4px;
}

.projects-item:hover .project-img {
    -moz-opacity: .2;
    opacity: .2;
}

.projects-item:hover .hover-img {
    background-color: #016ca4;
}

.projects-item .zoom-wrapper {
    position: absolute;
    width: 100%;
    top: 55px;
    text-align: center;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

.projects-item .zoom-link {
    font-family: fira_sansbook, sans-serif;
    font-size: 12px;
    border: solid 3px #eff3f7;
    color: #eff3f7;
    text-decoration: none;
    border-radius: 5px;
    padding: 0 4px;
}

.projects-item:hover .zoom-wrapper {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.projects-item .project-link {
    color: #3ba8c0;
    font-family: fira_sanslight_italic, sans-serif;
    font-size: 13px;
    text-decoration: none;
}

.projects-item .project-desc {
    font-size: 15px;
    margin: 5px 0;
    line-height: 1.2;
}

.add-new-item {
    border: dashed 1px #3ba8c0;
    border-radius: 4px;
    height: 128px;
    text-decoration: none;
}

.add-new-item .add-new-link {
    background: url(img/add-new.png) center 33px no-repeat;
    display: block;
    height: 100%;
    text-decoration: none;
    color: #3ba8c0;
    font-size: 13px;
}

.add-new-item .add-new-text {
    position: absolute;
    padding-top: 76px;
    text-align: center;
    width: 100%;
}

.add-new-item .add-new-link:hover {
    text-decoration: underline;
}

@-webkit-keyframes moveFromBottom {
    from {
        -webkit-transform: translateY(200%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@-moz-keyframes moveFromBottom {
    from {
        -moz-transform: translateY(200%);
        opacity: 0;
    }
    to {
        -moz-transform: translateY(0);
        opacity: 1;
    }
}

@-ms-keyframes moveFromBottom {
    from {
        -ms-transform: translateY(200%);
        opacity: 0;
    }
    to {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}

@-o-keyframes moveFromBottom {
    from {
        -o-transform: translateY(200%);
        opacity: 0;
    }
    to {
        -o-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes moveFromBottom {
    from {
        transform: translateY(200%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}